<template>
    <el-dialog :visible.sync="modulxModal" :before-close="closeModal" :close-on-click-modal="false" class="app-dialog" width="400px">
        <template slot="title">
            <title-icon/>{{ $t('module.open') }}
        </template>
        <template v-if="modulx.riseModule == 'Y'">
            <div>
                {{ $t('module.openTips1') }}
            </div>
            <p>
                {{ $t('module.openTips2') }}
            </p>
        </template>
        <template v-else>
            <span>
                {{ $t('module.openTips3') }}
            </span>
        </template>
        <template v-if="modulx.riseModule == 'Y'">
            <el-form :model="modulx" ref="modulxForm" :rules="formRule" label-width="70px">
                <el-form-item :label="$t('module.duration')" prop="unitPcs">
                    <el-input-number v-model="modulx.unitPcs" style="width: 112px;margin-right: 2px;" controls-position="right" :min="1" size="mini" @change="calTotalPrice"></el-input-number>
                    {{$t('module.duration' + modulx.priceUnit)}}
                </el-form-item>
                <el-form-item :label="$t('module.price')" prop="price">
                    <!--单价-->
                    <span>¥{{modulx.price}}/{{$t('module.unitPrice' + modulx.priceUnit)}}</span>
                </el-form-item>
                <el-form-item :label="$t('module.totalPrice')">
                    <!--总价-->
                    <span>¥{{totalPrice}}</span>
                </el-form-item>
            </el-form>
        </template>
        <div slot="footer">
            <el-button @click="closeModal">{{$t('commons.cancel') }}</el-button>
            <el-button type="primary" @click="submit">{{$t('commons.submit') }}</el-button>
        </div>
    </el-dialog>
</template>

<script>

let modulxApi = require('@/api/ruge/tenant/modulx')

export default {
    data() {
        return {
            totalPrice: 0,
            modulxModal: true,
            formRule:{}
        };
    },
    props: {
        modulx: {
            type: Object,
            required: true
        },
    },
    created() {
        this.$nextTick(()=>{
            this.calTotalPrice()
        })
    },
    watch: {
        modulx(mdx) {
            this.modulx = mdx
            this.calTotalPrice()
        }
    },
    methods: {
        //只作为提示，生成订单后台重新计算
        calTotalPrice() {
            this.totalPrice = (this.modulx.unitPcs*this.modulx.price).toFixed(2);
        },
        submit() {
            //管理员直接开通
            modulxApi._sys_openModulx({tenantId: this.modulx.tenantId, modules: [this.modulx]}).then(() => {
                this.$emit("onModalModulx", this.modulx)
            }).catch(() => {})
        },
        closeModal() {
            this.$emit("cancelModal")
        }
    }
}
</script>
