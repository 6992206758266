var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-remove": _vm.removeTab },
          model: {
            value: _vm.activeTabName,
            callback: function ($$v) {
              _vm.activeTabName = $$v
            },
            expression: "activeTabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              key: _vm.tenantTabName,
              attrs: {
                label: _vm.$t("tenant.manager"),
                name: _vm.tenantTabName,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-plus",
                            size: "small",
                          },
                          on: { click: _vm.addTenant },
                        },
                        [_vm._v(_vm._s(_vm.$t("tenant.addTenant")))]
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 6, offset: 16 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "filter-container",
                        attrs: { align: "right" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "filter-item",
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: _vm.$t("commons.searchPhrase"),
                            maxlength: "50",
                            clearable: "",
                            size: "small",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.queryTenant.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.tenants.listQuery.searchPhrase,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tenants.listQuery,
                                "searchPhrase",
                                $$v
                              )
                            },
                            expression: "tenants.listQuery.searchPhrase",
                          },
                        }),
                        _c(
                          "el-button",
                          {
                            staticClass: "filter-item",
                            attrs: {
                              size: "small",
                              type: "primary",
                              loading: _vm.loading,
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.queryTenant },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("commons.search")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    data: _vm.tenants.list,
                    "element-loading-text": "Loading",
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                    stripe: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: "center",
                      label: _vm.$t("commons.index"),
                      width: "95",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("tenant.tenantName"),
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.edit
                              ? [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "80",
                                      "show-word-limit": true,
                                      size: "small",
                                      clearable: "",
                                      placeholder: _vm.$t(
                                        "commons.pleaseInput"
                                      ),
                                    },
                                    model: {
                                      value: scope.row.tenantName,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "tenantName", $$v)
                                      },
                                      expression: "scope.row.tenantName",
                                    },
                                  }),
                                ]
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.tenantName)),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("tenant.productKey"),
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.productKey))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "creationDate",
                      align: "center",
                      label: _vm.$t("commons.creationTime"),
                      width: "220",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    scope.row.creationDate,
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: _vm.$t("commons.actions"),
                      width: "145",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.edit
                              ? [
                                  _c("div", { staticClass: "ruge-buttons" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "ruge-button",
                                        attrs: {
                                          title: _vm.$t("commons.save"),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.updateTenant(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-circle-check",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "ruge-button",
                                        attrs: {
                                          title: _vm.$t("commons.cancel"),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelTenantEdit(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-circle-close",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              : [
                                  _c("div", { staticClass: "ruge-buttons" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "ruge-button",
                                        attrs: {
                                          title: _vm.$t("commons.edit"),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editTenant(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-edit-outline",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "ruge-button",
                                        attrs: {
                                          title: _vm.$t("tenant.module"),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.opModule(scope.row)
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "el-icon-box" })]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "ruge-button",
                                        attrs: {
                                          title: _vm.$t("tenant.grantManager"),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.allowPersonal(scope.row)
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "el-icon-user" })]
                                    ),
                                  ]),
                                ],
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tenants.total > 0,
                    expression: "tenants.total > 0",
                  },
                ],
                attrs: {
                  total: _vm.tenants.total,
                  page: _vm.tenants.listQuery.current,
                  limit: _vm.tenants.listQuery.rowCount,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.tenants.listQuery, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.tenants.listQuery, "rowCount", $event)
                  },
                  pagination: _vm.getTenantList,
                },
              }),
              _c(
                "el-dialog",
                {
                  staticClass: "app-dialog",
                  attrs: {
                    visible: _vm.tenants.addTenantVisible,
                    width: "400px",
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.tenants, "addTenantVisible", $event)
                    },
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _c("title-icon"),
                      _vm._v(_vm._s(_vm.$t("tenant.addTenant")) + "\n        "),
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    {
                      ref: "tenantForm",
                      attrs: {
                        model: _vm.tenants.form,
                        rules: _vm.tenants.formRule,
                        "label-width": "80px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("tenant.tenantName"),
                            prop: "tenantName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "80",
                              "show-word-limit": true,
                              name: "tenantName",
                              type: "text",
                              "auto-complete": "on",
                              placeholder: _vm.$t("commons.pleaseInput"),
                              clearable: "",
                              autofocus: "",
                            },
                            model: {
                              value: _vm.tenants.form.tenantName,
                              callback: function ($$v) {
                                _vm.$set(_vm.tenants.form, "tenantName", $$v)
                              },
                              expression: "tenants.form.tenantName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("tenant.userAccount"),
                            prop: "userAccount",
                          },
                        },
                        [
                          _c("account-select", {
                            staticClass: "filter-item",
                            attrs: { size: "small", model: 1 },
                            on: { select: _vm.accountSelect },
                            model: {
                              value: _vm.tenants.form.userAccount,
                              callback: function ($$v) {
                                _vm.$set(_vm.tenants.form, "userAccount", $$v)
                              },
                              expression: "tenants.form.userAccount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("tenant.moduleTemplate"),
                            prop: "moduleTemplate",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                name: "moduleTemplate",
                                placeholder: _vm.$t("login.companyTemplate"),
                              },
                              on: { change: _vm.templateChange },
                              model: {
                                value: _vm.tenants.form.templateId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tenants.form, "templateId", $$v)
                                },
                                expression: "tenants.form.templateId",
                              },
                            },
                            _vm._l(_vm.templateList, function (item) {
                              return _c("el-option", {
                                key: item.templateId,
                                attrs: {
                                  label: item.templateName,
                                  value: item.templateId,
                                },
                              })
                            }),
                            1
                          ),
                          _vm.totalPrice > 0
                            ? [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("login.templateTotalPrice")) +
                                      _vm._s(_vm.totalPrice)
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { slot: "footer" }, slot: "footer" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.cancelAddTenant()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("commons.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.saveTenant()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("commons.save")))]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm.choiceModuleTab
            ? _c(
                "el-tab-pane",
                {
                  key: _vm.choiceModuleTab.tabName,
                  attrs: {
                    label: _vm.$t("tenant.selectModule"),
                    name: _vm.choiceModuleTab.tabName,
                    closable: "",
                  },
                },
                [
                  _c("el-tag", [_vm._v(_vm._s(_vm.currentRow.tenantName))]),
                  _c("sys-modulx", {
                    key: _vm.currentRow.tenantId,
                    attrs: { currentRow: _vm.currentRow },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }