<template>
  <div class="app-container">
    <el-tabs v-model="activeTabName" type="card" @tab-remove="removeTab">
      <el-tab-pane
        :label="$t('tenant.manager')"
        :name="tenantTabName"
        :key="tenantTabName"
      >
        <el-row>
          <el-col :span="2">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="small"
              @click="addTenant"
              >{{ $t("tenant.addTenant") }}</el-button
            >
          </el-col>
          <el-col :span="6" :offset="16">
            <div class="filter-container" align="right">
              <el-input
                :placeholder="$t('commons.searchPhrase')"
                maxlength="50"
                clearable
                v-model="tenants.listQuery.searchPhrase"
                size="small"
                style="width: 200px"
                class="filter-item"
                @keyup.enter.native="queryTenant"
              ></el-input>
              <el-button
                size="small"
                class="filter-item"
                type="primary"
                :loading="loading"
                icon="el-icon-search"
                @click="queryTenant"
              >
                {{ $t("commons.search") }}
              </el-button>
            </div>
          </el-col>
        </el-row>
        <el-table
          v-loading="loading"
          :data="tenants.list"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
          stripe
        >
          <el-table-column
            type="index"
            align="center"
            :label="$t('commons.index')"
            width="95"
          >
          </el-table-column>
          <el-table-column :label="$t('tenant.tenantName')" align="center">
            <template slot-scope="scope">
              <template v-if="scope.row.edit">
                <el-input
                  v-model="scope.row.tenantName"
                  maxlength="80"
                  :show-word-limit="true"
                  size="small"
                  clearable
                  :placeholder="$t('commons.pleaseInput')"
                />
              </template>
              <span v-else>{{ scope.row.tenantName }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('tenant.productKey')" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.productKey }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="creationDate"
            align="center"
            :label="$t('commons.creationTime')"
            width="220"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            :label="$t('commons.actions')"
            width="145"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.edit">
                <div class="ruge-buttons">
                  <a
                    class="ruge-button"
                    @click="updateTenant(scope.row)"
                    :title="$t('commons.save')"
                    ><i class="el-icon-circle-check"></i
                  ></a>
                  <a
                    class="ruge-button"
                    @click="cancelTenantEdit(scope.row)"
                    :title="$t('commons.cancel')"
                    ><i class="el-icon-circle-close"></i
                  ></a>
                </div>
              </template>
              <template v-else>
                <div class="ruge-buttons">
                  <a
                    class="ruge-button"
                    @click="editTenant(scope.row)"
                    :title="$t('commons.edit')"
                    ><i class="el-icon-edit-outline"></i
                  ></a>
                  <a
                    class="ruge-button"
                    @click="opModule(scope.row)"
                    :title="$t('tenant.module')"
                    ><i class="el-icon-box"></i
                  ></a>
                  <a
                    class="ruge-button"
                    @click="allowPersonal(scope.row)"
                    :title="$t('tenant.grantManager')"
                    ><i class="el-icon-user"></i
                  ></a>
                </div>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="tenants.total > 0"
          :total="tenants.total"
          :page.sync="tenants.listQuery.current"
          :limit.sync="tenants.listQuery.rowCount"
          @pagination="getTenantList"
        />
        <el-dialog
          class="app-dialog"
          :visible.sync="tenants.addTenantVisible"
          width="400px"
        >
          <template slot="title">
            <title-icon />{{ $t("tenant.addTenant") }}
          </template>
          <el-form
            :model="tenants.form"
            ref="tenantForm"
            :rules="tenants.formRule"
            label-width="80px"
          >
            <el-form-item :label="$t('tenant.tenantName')" prop="tenantName">
              <el-input
                v-model="tenants.form.tenantName"
                maxlength="80"
                :show-word-limit="true"
                name="tenantName"
                type="text"
                auto-complete="on"
                :placeholder="$t('commons.pleaseInput')"
                clearable
                autofocus
              >
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('tenant.userAccount')" prop="userAccount">
              <account-select
                size="small"
                class="filter-item"
                @select="accountSelect"
                v-model="tenants.form.userAccount"
                :model="1"
              ></account-select>
            </el-form-item>
            <el-form-item
              :label="$t('tenant.moduleTemplate')"
              prop="moduleTemplate"
            >
              <el-select
                v-model="tenants.form.templateId"
                name="moduleTemplate"
                @change="templateChange"
                :placeholder="$t('login.companyTemplate')"
              >
                <el-option
                  v-for="item in templateList"
                  :key="item.templateId"
                  :label="item.templateName"
                  :value="item.templateId"
                >
                </el-option>
              </el-select>
              <template v-if="totalPrice > 0">
                <span
                  >{{ $t("login.templateTotalPrice") }}{{ totalPrice }}</span
                >
              </template>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="cancelAddTenant()">{{
              $t("commons.cancel")
            }}</el-button>
            <el-button type="primary" @click="saveTenant()">{{
              $t("commons.save")
            }}</el-button>
          </div>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane
        v-if="choiceModuleTab"
        :label="$t('tenant.selectModule')"
        :name="choiceModuleTab.tabName"
        :key="choiceModuleTab.tabName"
        closable
      >
        <el-tag>{{ currentRow.tenantName }}</el-tag>
        <sys-modulx
          v-bind:currentRow="currentRow"
          :key="currentRow.tenantId"
        ></sys-modulx>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
let manager = require("@/api/ruge/tenant/tenant.js");

import { getTemplateList } from "@/api/ruge/tenant/templatex";
import Pagination from "@/components/Pagination";
import AccountSelect from "@/components/AccountSelect";
import SysModulx from "@/views/ruge/modulx/sysModulx";

export default {
  name: "TenantManager",
  components: { Pagination, AccountSelect, SysModulx },
  data() {
    return {
      loading: true,
      currentRow: {
        tenantId: null,
        tenantName: "",
      },
      activeTabName: "TenantList",
      tenantTabName: "TenantList",
      choiceModuleTab: null,
      templateList: [],
      totalPrice: 0,
      tenants: {
        addTenantVisible: false,
        list: null,
        total: 0,
        listQuery: {
          current: 1,
          rowCount: 10,
          searchPhrase: undefined,
        },
        form: {
          tenantName: null,
          userAccount: null,
          templateId: null,
        },
        formRule: {
          tenantName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          userAccount: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          templateId: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
      },
    };
  },
  created() {
    this.getTenantList();
    this.initSelect();
  },
  methods: {
    getTenantList() {
      this.loading = true;
      manager
        .getTenantList(this.tenants.listQuery)
        .then((response) => {
          this.tenants.list = response.rows.map((v) => {
            // 编辑状态，用于行内编辑
            this.$set(v, "edit", false);
            this.setOrgTenant(v);
            return v;
          });
          this.tenants.total = response.total;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(`查询失败，原因 => ${error}`);
        });
    },
    initSelect() {
      //获取模版
      getTemplateList().then((r) => {
        this.templateList = r;
      });
    },
    templateChange(template) {
      this.totalPrice = template.totalPrice;
    },
    queryTenant() {
      this.tenants.listQuery.current = 1;
      this.getTenantList();
    },
    editTenant(row) {
      row.edit = true;
    },
    deleteTenant(row) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          // 调用服务
          manager
            .deleteTenant(row)
            .then(() => {
              // 成功提示
              this.$message({
                type: "success",
                message: this.$t("message.deleteSuccess"),
              });
              // 刷新列表
              this.getTenantList();
            })
            .catch(() => {});
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    updateTenant(row) {
      // 租户名称不能为空
      if (!row.tenantName || row.tenantName.trim() === "") {
        this.$message({
          type: "warning",
          message: this.$t("tenant.message.notNull"),
        });
        return;
      }
      manager
        .updateTenant(row)
        .then(() => {
          row.edit = false;
          this.setOrgTenant(row);
          this.$message({
            type: "success",
            message: this.$t("message.saveSuccess"),
          });
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    cancelTenantEdit(row) {
      this.resetOrgTenant(row);
      row.edit = false;
    },
    setOrgTenant(row) {
      row.orgTenantName = row.tenantName;
    },
    resetOrgTenant(row) {
      row.tenantName = row.orgTenantName;
    },
    // 添加租户
    addTenant() {
      this.tenants.addTenantVisible = true;
    },
    // 退出编辑
    cancelAddTenant() {
      this.tenants.addTenantVisible = false;
    },
    // 账号选择
    accountSelect(item) {
      this.tenants.form.userId = item.userId;
    },
    //保存租户
    saveTenant() {
      this.$refs.tenantForm.validate((valid) => {
        if (valid) {
          manager
            .saveTenant(this.tenants.form)
            .then(() => {
              this.tenants.form.tenantName = null;
              this.tenants.addTenantVisible = false;
              this.getTenantList();
              this.$message({
                type: "success",
                message: this.$t("message.saveSuccess"),
              });
            })
            .catch(() => {
              this.tenants.addTenantVisible = false;
            });
        }
      });
    },
    opModule(row) {
      this.currentRow.tenantId = row.tenantId;
      this.currentRow.tenantName = row.tenantName;
      this.choiceModuleTab = { tabName: "TenantModule" };
      this.activeTabName = "TenantModule";
    },
    allowPersonal() {
      this.$router.push({
        name: "GrantManager",
      });
      // this.$router.push({ path: "/sys/permission/grant/" });
    },
    removeTab() {
      this.choiceModuleTab = null;
      this.activeTabName = "TenantList";
    },
  },
};
</script>

<style scoped>
.app-dialog >>> .el-form-item {
  margin-bottom: 0px;
}
</style>
