<template>
    <div>
        <div v-loading="loading">
            <el-row>
                <template v-if="moduleList.length > 0">
                    <el-col class="ruge-cards" :span="5" v-for="(modulx, index) in moduleList" :key="modulx.moduleCode" :offset="index % 4==0 ? 0 : 1">
                        <el-card class="moduleCard">
                            <div class="moduleCardHeader" slot="header">
                                <title-icon/><span>{{modulx.moduleName}}</span>
                                <template v-if="modulx.moduleStatus">
                                    <el-tag :type="modulx.moduleStatus | statusTagFilter" style="float: right;">{{$t('module.moduleStatus' + modulx.moduleStatus)}}</el-tag>
                                </template>
                                <template v-else>
                                    <el-tag type="info" style="float: right;">{{$t('module.notOpen')}}</el-tag>
                                </template>
                            </div>
                            <div>
                                <template v-if="modulx.riseModule == 'Y'">
                                    <span>{{$t('module.riseModule')}}</span>
                                    <template v-if="modulx.moduleStatus == 2 || modulx.moduleStatus == 7" >
                                        <!--显示服务截止时间-->
                                        <template v-if="modulx.expireDate">
                                           <span style="float: right;">{{$t('module.expireDate')}}:{{modulx.expireDate | dateFormat('YYYY-MM-DD') }}</span>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <span style="float: right;">¥{{modulx.price}}/{{$t('module.unitPrice' + modulx.priceUnit)}}</span>
                                    </template>
                                </template>
                                <template v-else-if="modulx.riseModule == 'N'">
                                    <span>{{$t('module.freeModule')}}</span>
                                </template>
                                <template v-else>
                                    <span>{{$t('module.unPricing')}} </span>
                                </template>
                                <div class="bottom">
                                    <div>
                                        <template v-if="!modulx.moduleStatus || modulx.moduleStatus == 3 || modulx.moduleStatus==6">
                                            <el-button @click="onOpenModulx(modulx)" type="text">{{$t('module.open')}}</el-button>
                                        </template>
                                        <template v-if="modulx.moduleStatus == 2 || modulx.moduleStatus==7">
                                            <template v-if="modulx.riseModule == 'Y'">
                                                <el-button @click="onRenewalModulx(modulx)" type="text">{{$t('module.renewal')}}</el-button>
                                            </template>
                                            <el-button @click="onCloseModulx(modulx)" type="text">{{$t('module.close')}}</el-button>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </template>
            </el-row>

            <template v-if="openModuleModal">
                <sys-open-modulx @cancelModal="cancelModalOpen" :modulx="currentOpenModulx" @onModalModulx="onModalModulx"></sys-open-modulx>
            </template>
        </div>
    </div>
</template>

<style rel="stylesheet/scss" lang="scss" scoped>
    .ruge-cards {
        .el-card {
            cursor: auto;
        }
        .moduleCardHeader {
            line-height: 30px;
        }
    }

    .bottom {
        margin-top: 13px;
        line-height: 12px;
        text-align: right;
    }
</style>

<style scoped>
    .moduleCard >>> .el-card__header {
        padding: 10px 16px;
    }
</style>

<script>

let modulxApi = require('@/api/ruge/tenant/modulx')
import SysOpenModulx from '@/views/ruge/modulx/sysOpenModulx'

export default {
    components: {SysOpenModulx},
    filters: {
        // 返回状态tag效果
        statusTagFilter(status) {
            let str = '';
            if (status === 2) {
                str = 'success';
            } else if (status === 3 || status === 7) {
                str = 'danger';
            } else if (status === 6) {
                str = 'info';
            }
            return str;
        },
    },
    data() {
        return {
            loading: true,
            moduleList: [],
            openModuleModal: false,
            currentOpenModulx: {},
        };
    },
    props: {
        currentRow: {
            type: Object,
            required: true
        }
    },
    created() {
        this.getAllModule()
    },
    methods: {
        getAllModule() {
            if(!this.currentRow.tenantId) return;
            this.loading = true;
            modulxApi._sys_findOpendModuleList({tenantId: this.currentRow.tenantId}).then(rsp=>{
                rsp.map(r=>{
                    r.moduleName = r.moduleName.length>15?r.moduleName.substring(0, 15)+"...":r.moduleName;
                });
                this.moduleList = rsp;
            }).finally(() => {
                this.loading = false;
            })
        },
        onOpenModulx(modulx) {
            modulx.tenantId = this.currentRow.tenantId;
            this.currentOpenModulx = modulx;
            this.openModuleModal = true;
        },
        onModalModulx() {
            this.currentOpenModulx = {};
            this.openModuleModal = false;
            this.getAllModule();
        },
        cancelModalOpen() {
            this.currentOpenModulx = {};
            this.openModuleModal = false;
        },
        onCloseModulx(modulx) {
            this.$confirm(this.$t('module.message.closeConfirm2'), this.$t('module.message.closeConfirm1'), {
                confirmButtonText: this.$t('commons.confirm'),
                cancelButtonText: this.$t('commons.cancel'),
                type: 'warning'
            }).then(() => {
                // 调用服务
                modulxApi._sys_closeModulx({tenantId: this.currentRow.tenantId, modules: [{moduleCode: modulx.moduleCode}]}).then(() => {
                    // 成功提示
                    this.$message({
                        type: 'success',
                        message: this.$t('message.operationSuccess')
                    });
                    // 刷新列表
                    this.getAllModule();
                }).catch(() => {})
            }).catch(error => {
                console.log(`未删除，原因 => ${error}`)
            });
        },
        onRenewalModulx(modulx) {
            if(modulx) {
                this.$message({
                    type: 'info',
                    message: this.$t('module.message.unsupport')
                });
            }
        }
    }
}
</script>
